/* eslint-disable no-nested-ternary */
import Head from "next/head";
import React from "react";
import useGTMOnInteraction from "hooks/useGTMOnInteraction";
import config from "../config";

const IndexingLinks = {
	"jobs-in-mumbai": "android-app://l.apna.co/PzZrzT4kSkb",
	"jobs-in-bengaluru": "android-app://l.apna.co/O9ZXvcilSkb",
	"jobs-in-chennai": "android-app://l.apna.co/zucBLhnlSkb",
	"jobs-in-agra": "android-app://l.apna.co/4fjTJzXlSkb",
	"jobs-in-asansol": "android-app://l.apna.co/4kr9eZ1lSkb",
	"jobs-in-amritsar": "android-app://l.apna.co/uzQxeY5lSkb",
	"jobs-in-ahmedabad": "android-app://l.apna.co/GoNSyS9lSkb",
	"jobs-in-aurangabad": "android-app://l.apna.co/m8AAOEfmSkb",
	"jobs-in-bhopal": "android-app://l.apna.co/rIEoT0jmSkb",
	"jobs-in-bhubaneswar": "android-app://l.apna.co/YKu45msmSkb",
	"jobs-in-chandigarh": "android-app://l.apna.co/8Acmk4vmSkb",
	"jobs-in-coimbatore": "android-app://l.apna.co/3I3XbaAmSkb",
	"jobs-in-goa": "android-app://l.apna.co/PGsSRJLmSkb",
	"jobs-in-guwahati": "android-app://l.apna.co/fVFqdYQmSkb",
	"jobs-in-gwalior": "android-app://l.apna.co/9JUF0punSkb",
	"jobs-in-hyderabad": "android-app://l.apna.co/3eCSGBxnSkb",
	"jobs-in-indore": "android-app://l.apna.co/RKYhIsBnSkb",
	"jobs-in-jaipur": "android-app://l.apna.co/dLUucTHnSkb",
	"jobs-in-jodhpur": "android-app://l.apna.co/l2KrmhMnSkb",
	"jobs-in-kanpur": "android-app://l.apna.co/5jFjnQRnSkb",
	"jobs-in-kochi": "android-app://l.apna.co/xSndQWUnSkb",
	"jobs-in-kolkata": "android-app://l.apna.co/ZI6VHuYnSkb",
	"jobs-in-lucknow": "android-app://l.apna.co/gtuDaR1nSkb",
	"jobs-in-ludhiana": "android-app://l.apna.co/45U63x5nSkb",
	"jobs-in-madurai": "android-app://l.apna.co/NsapJfcoSkb",
	"jobs-in-malappuram": "android-app://apna.co/jobs/jobs-in-malappuram",
	"jobs-in-mysuru": "android-app://l.apna.co/2sfP3tloSkb",
	"jobs-in-nagpur": "android-app://l.apna.co/zwbetlvoSkb",
	"jobs-in-nashik": "android-app://l.apna.co/o2IZ0qDoSkb",
	"jobs-in-patna": "android-app://l.apna.co/PjD8bFHoSkb",
	"jobs-in-prayagraj": "android-app://l.apna.co/wpnAfsNoSkb",
	"jobs-in-pune": "android-app://l.apna.co/6mQXiPSoSkb",
	"jobs-in-raipur": "android-app://l.apna.co/BjbLEy2oSkb",
	"jobs-in-rajkot": "android-app://l.apna.co/Ek2q525oSkb",
	"jobs-in-ranchi": "android-app://l.apna.co/BDPBPpapSkb",
	"jobs-in-surat": "android-app://l.apna.co/EEGhNjdpSkb",
	"jobs-in-thiruvananthapuram": "android-app://l.apna.co/ShwMCVgpSkb",
	"jobs-in-vadodara": "android-app://l.apna.co/0ynRQEkpSkb",
	"jobs-in-varanasi": "android-app://l.apna.co/hUyxJUopSkb",
	"jobs-in-vijayawada": "android-app://l.apna.co/LX3vMfApSkb",
	"jobs-in-visakhapatnam": "android-app://l.apna.co/D03D1JJpSkb",
	"jobs-in-delhi_ncr": "android-app://l.apna.co/Apck4SblSkb",
};

const SiteMetaData = {
	title: `Apna.co: Hire Candidates | Post Job | Search Jobs Online`,
	ogTitle: `Hire In 48 hours. Fastest job posting, online recruitment portal, find a job in 1 day - apna.co`,
	description: `Apna is the biggest job groups platform in India. On Apna you can find all types of jobs from entry level to experienced people in over 200+ categories.`,
	ogDescription: `We provide jobs and candidates for telecalling, back office, accounts, receptionist, delivery, logistics, warehouse, loaders and packers, technicians, engineers, drivers, sales representatives, business development, admin, office boy, office assistant, computer operators, data entry operators, marketing, retail, security guards, trainers. With apna.co a Job seeker can search the best jobs throughout Mumbai & Delhi - NCR and schedule the Interview at their convenience. An employer can post the vacancy and get applications directly.`,
	author: `apna`,
	url: "https://apna.co",
	siteUrl: "https://apna.co",
	name: "apna.co",
};

function SEO({
	description,
	title,
	image,
	metaTitle,
	pageType,
	publishedAt,
	slug,
	disableIndexing = false,
	pageNumber = null,
	is404,
	ogUrl,
	ogImageUrl,
}) {
	const displayPageNumber =
		pageNumber && pageNumber !== 1 ? `| Page ${pageNumber}` : "";
	const pageTitle = title ? `${title} ${displayPageNumber}` : SiteMetaData.title;
	const ogTitle = title
		? `${title} ${displayPageNumber} | ${SiteMetaData.name}`
		: SiteMetaData.ogTitle;
	const metaImage = image || "https://apna.co/apna-time-icon.png";
	const metaDescription = !is404
		? `${description} ${displayPageNumber}` || SiteMetaData.description
		: "Sorry, the page you're looking for isn't here. Please check the URL or go back to our homepage.";
	const metaOgDescription = !is404
		? `${description} ${displayPageNumber}` || SiteMetaData.ogDescription
		: "Sorry, the page you're looking for isn't here. Please check the URL or go back to our homepage.";
	const metaTitleText = !is404
		? `${metaTitle} ${displayPageNumber} | ${SiteMetaData.name}`
		: `Page Not Found - ${SiteMetaData.name}`;

	useGTMOnInteraction("DC-11888069");

	return (
		<Head>
			<title>{pageTitle}</title>
			{/* Main Data */}
			<meta name="title" content={metaTitleText} />
			<meta name="description" content={metaDescription} />
			<meta name="image" content={metaImage} />
			<link
				rel="canonical"
				href={`${config.websiteUrl.slice(0, -1)}${slug ? `${slug}` : ""}`}
			/>
			{IndexingLinks[slug?.substr(6)] ? null : (
				<link
					rel="alternate"
					href={`${config.websiteUrl.slice(0, -1)}${slug ? `${slug}` : ""}`}
					hrefLang="en-in"
				/>
			)}
			{/* Some Business Information */}
			<meta name="author" content="apna" />
			<meta property="place:location:latitude" content="19.1176" />
			<meta property="place:location:longitude" content="72.9060" />
			<meta property="business:contact_data:locality" content="Mumbai" />
			<meta property="business:contact_data:postal_code" content="400076" />
			<meta property="business:contact_data:country_name" content="India" />
			<meta property="business:contact_data:email" content="support@apna.co" />
			<meta property="business:contact_data:phone_number" content="7700077470" />
			<meta
				property="business:contact_data:website"
				content={SiteMetaData.siteUrl}
			/>
			<meta
				itemProp="name"
				content="apna - Find a candidate in 48 hours, Find a job in 1 day"
			/>
			<meta property="profile:username" content="apna" />
			<meta
				itemProp="description"
				content="apna - Job Search | Job Groups | Job Prepartion"
			/>

			{/* OG Data */}
			<meta property="og:title" content={ogTitle} />
			<meta property="og:description" content={metaOgDescription} />
			<meta property="og:image" content={ogImageUrl || metaImage} />
			<meta property="og:type" content={pageType || "website"} />
			{pageType === "article" && publishedAt ? (
				<meta property="article:published_time" content={publishedAt} />
			) : null}
			{pageType === "article" ? (
				<meta property="article:author" content="Apna" />
			) : null}
			<meta
				property="og:url"
				content={
					ogUrl || `${config.websiteUrl.slice(0, -1)}${slug ? `${slug}` : ""}`
				}
			/>
			<meta
				property="og:determiner"
				content="Post Jobs To Get Candidates | Hire Staff Easily In 48  Hours"
			/>

			{/* Twitter Data */}
			<meta name="twitter:title" content={ogTitle} />
			<meta name="twitter:description" content={metaOgDescription} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:creator" content={SiteMetaData.author} />
			<meta
				name="twitter:image"
				content={
					image || `https://apna.co/meta-image.jpg?${new Date().getTime()}`
				}
			/>
			<meta
				name="google-site-verification"
				content="cPMXj-SYK3XoDoly4pxw8fk2j9GuCoXG5xbNi-zfMcw"
			/>
			<meta
				name="google-site-verification"
				content="10mNwp4MlyM58zIjw6yEc_LVs7rKSCz8T1gAUv166mc"
			/>
			<meta
				name="robots"
				content={
					config.env !== "production"
						? disableIndexing
							? "noindex,follow"
							: "noindex,nofollow"
						: "index,follow"
				}
			/>
		</Head>
	);
}

export default SEO;
