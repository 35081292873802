import React, { ReactNode } from "react";
import { useTheme } from "styled-components";
import SEO from "components/seo";
import Navbar from "./Navbar";
import ViNavbar from "./ViNavbar";
// import FooterSocialContainer from "components/ResponsiveFooterDark/FooterSocialContainer";

type LayoutProps = {
	isLoggedIn: boolean;
	source: string;
	title: string;
	metaDescription?: string;
	metaTitle?: string;
	image?: string;
	slug?: string;
	logo: ReactNode;
	activePage?: string;
};

const Layout: React.FC<LayoutProps> = ({
	children,
	isLoggedIn,
	title,
	image,
	metaTitle,
	metaDescription,
	slug,
	logo = null,
	source = "",
	activePage = "job",
}) => {
	const { vendor } = useTheme();

	return (
		<>
			<SEO
				title={title}
				description={metaDescription}
				metaTitle={metaTitle}
				image={image}
				slug={slug}
				publishedAt={null}
				pageType={null}
				disableIndexing
			/>
			<div>
				{vendor === "vi" ? (
					<ViNavbar source={source} logo={logo} isLoggedIn={isLoggedIn} />
				) : (
					<Navbar
						activePage={activePage}
						source={source}
						logo={logo}
						isLoggedIn={isLoggedIn}
					/>
				)}
				{children}
			</div>
			{/* <FooterSocialContainer source={source} customUtmMedium={null} /> */}
		</>
	);
};

Layout.defaultProps = {
	metaDescription: "",
	metaTitle: "",
	image: "",
	slug: "",
	activePage: "",
};

export default Layout;
