import React from "react";
import styled from "styled-components";

const Container = ({ children }) => (
	<div>
		<Wrapper>{children}</Wrapper>
	</div>
);

export default Container;

const Wrapper = styled.div`
	margin: auto;
	max-width: 1040px;
`;
