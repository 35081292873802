import React, { ReactNode } from "react";

import ApnaViLogo from "assets/svgs/ApnaViLogo";
import ViUserIcon from "assets/svgs/ViUserIcon";
import Link from "next/link";
import { bp } from "theme";
import styled, { useTheme } from "styled-components";
import config from "config";
import { getUrlPath } from "utils/helpers";

import { useUser } from "hooks";

type ViNavbarProps = {
	logo?: ReactNode;
	isLoggedIn: boolean;
	source: string;
};

const ViNavbar: React.FC<ViNavbarProps> = ({ logo, isLoggedIn, source }) => {
	const { user } = useUser();
	const { vendor } = useTheme();

	const trackProfileIconClick = () => {
		import("../../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Web_Profile Icon Click", {
				section: "Header",
				Source: source,
				page: getUrlPath(),
				userPlatform: vendor === "apna" ? "Apna Main Website" : "VI Integration",
				cta: "Profile Icon",
			});
		});
	};

	const userProfileLink = user
		? `/microsite/vi/${user?.profile_url?.replace(config.websiteUrl, "")}`
		: "/login";
	return (
		<Navbar>
			<Container>
				<LogoContainer>{logo || <ApnaViLogo />}</LogoContainer>
				<ProfileIconContainer onClick={trackProfileIconClick}>
					{isLoggedIn && user?.id && (
						// eslint-disable-next-line @next/next/link-passhref
						<Link href={userProfileLink}>
							<ProfileIconContainer>
								<ViUserIcon />
							</ProfileIconContainer>
						</Link>
					)}
				</ProfileIconContainer>
			</Container>
		</Navbar>
	);
};

export default ViNavbar;

const Navbar = styled.nav`
	background: ${({ theme }) => theme.colors.primary};
`;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1040px;
	margin: auto;
	padding: 24px;

	${bp.xl} {
		padding: 24px 0;
	}
`;

const LogoContainer = styled.div``;

const ProfileIconContainer = styled.div`
	height: 24px;
	width: 24px;
	cursor: pointer;
`;

ViNavbar.defaultProps = {
	logo: undefined,
};
