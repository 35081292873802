import React from "react";

const UserIcon = () => {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.3335 12C15.0949 12 17.3335 9.76142 17.3335 7C17.3335 4.23858 15.0949 2 12.3335 2C9.57207 2 7.3335 4.23858 7.3335 7C7.3335 9.76142 9.57207 12 12.3335 12Z"
				fill="white"
			/>
			<path
				d="M12.3333 13.666C6.70833 13.666 4 17.176 4 19.221V20.3327C4 20.7747 4.17559 21.1986 4.48816 21.5112C4.80072 21.8238 5.22464 21.9993 5.66667 21.9993H19C19.442 21.9993 19.866 21.8238 20.1785 21.5112C20.4911 21.1986 20.6667 20.7747 20.6667 20.3327V19.221C20.6667 17.176 17.9583 13.666 12.3333 13.666Z"
				fill="white"
			/>
		</svg>
	);
};

export default UserIcon;
