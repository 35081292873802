import React from "react";
import styled from "styled-components";

const ErrorText = ({ children }) => (
	<div>
		<ErrorMessage>{children}</ErrorMessage>
	</div>
);

export default ErrorText;

const ErrorMessage = styled.div`
	font-size: 12px;
	line-height: 2;
	color: #f85963;
`;
