import * as React from "react";

function CheckmarkIcon({ fill = "#2BB792" }) {
	return (
		<svg
			width="46"
			height="46"
			viewBox="0 0 46 46"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_4649_86241)">
				<path
					d="M32.9461 15.8606C33.6327 16.5472 33.6327 17.6603 32.9461 18.3466L21.1536 30.1394C20.467 30.8257 19.3543 30.8257 18.6676 30.1394L13.0539 24.5254C12.3673 23.8391 12.3673 22.726 13.0539 22.0397C13.7402 21.3531 14.8533 21.3531 15.5396 22.0397L19.9104 26.4106L30.4601 15.8606C31.1467 15.1743 32.2598 15.1743 32.9461 15.8606ZM45.5 23C45.5 35.4369 35.4352 45.5 23 45.5C10.5631 45.5 0.5 35.4352 0.5 23C0.5 10.5631 10.5648 0.5 23 0.5C35.4369 0.5 45.5 10.5648 45.5 23ZM41.9844 23C41.9844 12.5063 33.4923 4.01562 23 4.01562C12.5063 4.01562 4.01562 12.5077 4.01562 23C4.01562 33.4937 12.5077 41.9844 23 41.9844C33.4937 41.9844 41.9844 33.4923 41.9844 23Z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_4649_86241">
					<rect
						width="45"
						height="45"
						fill="white"
						transform="translate(0.5 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default CheckmarkIcon;
