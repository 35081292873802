/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";

const LABEL_SIDE_OPTIONS = {
	left: "left",
	right: "right",
};

const RadioInput = ({ label, labelSide = LABEL_SIDE_OPTIONS.right, ...props }) => (
	<RadioLabel>
		{labelSide === LABEL_SIDE_OPTIONS.left && <span>{label}</span>}
		<Input type="radio" {...props} />
		<CheckMark className="checkmark" />
		{labelSide !== LABEL_SIDE_OPTIONS.left && <span>{label}</span>}
	</RadioLabel>
);

export default RadioInput;

const RadioLabel = styled.label`
	display: ${(props) => (props.inline ? "inline" : "block")};
	position: relative;
	cursor: pointer;
	font-size: 12px;
	user-select: none;

	display: flex;
	align-items: center;

	span {
		order: 2;
		margin-left: 10px;
	}

	input:checked ~ .checkmark::after {
		display: block;
	}
`;

const Input = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;

	&:focus {
		& + span {
			box-shadow: 0px 0px 0px 4px #859fbc30;
		}
	}

	&:disabled {
		& + span {
			opacity: 0.7;
			cursor: default;
		}
	}
`;

const CheckMark = styled.span`
	order: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	width: 20px;
	background-color: transparent;
	border-radius: 50%;
	border: 2px solid #31445a;

	&:hover {
		box-shadow: 0px 0px 0px 4px #859fbc30;
	}

	&::after {
		display: none;
		content: "";
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: #31445a;
	}
`;
