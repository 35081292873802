import React from "react";

const UserOutlineIcon = () => {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.8">
				<path
					d="M16.8335 7C16.8335 9.48528 14.8188 11.5 12.3335 11.5C9.84821 11.5 7.8335 9.48528 7.8335 7C7.8335 4.51472 9.84821 2.5 12.3335 2.5C14.8188 2.5 16.8335 4.51472 16.8335 7Z"
					stroke="white"
				/>
				<path
					d="M4.5 19.221C4.5 18.3924 5.07083 17.1334 6.37456 16.0631C7.65938 15.0083 9.6286 14.166 12.3333 14.166C15.0381 14.166 17.0073 15.0083 18.2921 16.0631C19.5958 17.1334 20.1667 18.3924 20.1667 19.221V20.3327C20.1667 20.6421 20.0438 20.9388 19.825 21.1576C19.6062 21.3764 19.3094 21.4993 19 21.4993H5.66667C5.35725 21.4993 5.0605 21.3764 4.84171 21.1576C4.62292 20.9388 4.5 20.6421 4.5 20.3327V19.221Z"
					stroke="white"
				/>
			</g>
		</svg>
	);
};

export default UserOutlineIcon;
