import React from "react";
import { UserContextProvider } from "context/Job/UserContext";
import { useRouter } from "next/router";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { theme, Vendors } from "theme";

const ThemedLayout: React.FC = ({ children }) => {
	const router = useRouter();

	// TODO:	Remove default value of vi as vendor
	const vendor = (router.query.vendor as Vendors) || "vi";

	return (
		<UserContextProvider>
			{vendor === "vi" && <ViGlobalStyles />}
			{vendor ? (
				<ThemeProvider theme={theme[vendor || "vi"]}>
					<div>{children}</div>
				</ThemeProvider>
			) : null}
		</UserContextProvider>
	);
};

export default ThemedLayout;

const ViGlobalStyles = createGlobalStyle`
	@font-face {
		font-family: 'Vi';
		src: url('/Vi-Regular.woff') format('woff');
		font-weight: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Vi';
		src: url('/Vi-Medium.woff') format('woff');
		font-weight: medium;
	}

	@font-face {
		font-family: 'Vi';
		src: url('/Vi-SemiBold.woff') format('woff');
		font-weight: 500;
	}

	@font-face {
		font-family: 'Vi';
		src: url('/Vi-SemiBold.woff') format('woff');
		font-weight: 600;
	}

	@font-face {
		font-family: 'Vi';
		src: url('/Vi-Bold.woff') format('woff');
		font-weight: bold;
	}

	body * {
		font-family: Vi;
	}
`;
