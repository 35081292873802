import React from "react";

const JobBagOutlineIcon = () => {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.8">
				<path
					d="M1.82737 9.24876C1.6802 7.77705 2.83591 6.5 4.31496 6.5H19.685C21.1641 6.5 22.3198 7.77704 22.1726 9.24876L21.6826 14.1493C21.6059 14.9161 20.9607 15.5 20.19 15.5H3.80997C3.03935 15.5 2.3941 14.9161 2.31742 14.1493L1.82737 9.24876Z"
					stroke="white"
				/>
				<path
					d="M2.65803 16.0707C2.615 15.7695 2.84873 15.5 3.15301 15.5H20.847C21.1513 15.5 21.385 15.7695 21.342 16.0707L20.9956 18.495C20.7493 20.2192 19.2726 21.5 17.5308 21.5H6.46918C4.7274 21.5 3.25068 20.2192 3.00436 18.495L2.65803 16.0707Z"
					stroke="white"
				/>
				<path
					d="M7.5 5C7.5 3.067 9.067 1.5 11 1.5H13C14.933 1.5 16.5 3.067 16.5 5V6.5H7.5V5Z"
					stroke="white"
				/>
				<rect x={11} y={13} width={2} height={4} rx={1} fill="white" />
			</g>
		</svg>
	);
};

export default JobBagOutlineIcon;
