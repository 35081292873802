/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React from "react";
import styled, { useTheme } from "styled-components";
import Link from "next/link";
import { bp } from "theme";
import JobBagIcon from "assets/svgs/JobBagIcon";
import JobBagOutlineIcon from "assets/svgs/JobBagOutlineIcon";
import UserIcon from "assets/svgs/UserIcon";
import UserOutlineIcon from "assets/svgs/UserOutlineIcon";
import { useUser } from "hooks";
import CustomImage from "components/Image";
import config from "config";
import { createBranchDeepLink } from "libs/BranchIO";
import { BRANCH_APP_DOWNLOAD_CAMPAIGNS } from "utils/constants";
import Container from "components/ThemedComponent/Common/Container";
import { Button } from "components/GlobalComponents";
import ApnaLogo from "assets/svgs/ApnaLogoV2";
import PlayStoreButton from "assets/svgs/PlayStoreButton";
import { USER_PLATFORM } from "constants/tracking";

const DefaultLogo = () => (
	<LogoStyles>
		<ApnaLogo />
	</LogoStyles>
);

const Navbar = ({ logo = null, isLoggedIn, activePage = "job", source = "" }) => {
	const { user } = useUser();
	const { vendor } = useTheme();

	const userProfileLink = user
		? `/${user?.profile_url?.replace(config.websiteUrl, "")}`
		: "/login";

	const getUserIntials = () => {
		let initials = "";
		if (user?.full_name) {
			const [firstName, lastName] = user.full_name.split(" ");
			initials = `${firstName ? firstName[0]?.toUpperCase() : ""}${
				lastName ? lastName[0]?.toUpperCase() : ""
			}`;
		}

		return initials;
	};

	const openPlayStoreUrl = async () => {
		const playStoreUrl = await createBranchDeepLink({
			id: "",
			campaign: BRANCH_APP_DOWNLOAD_CAMPAIGNS.downloadAppCTA,
			pageType: source,
			section: "Navigation Bar",
			userPlatform: USER_PLATFORM[vendor],
		} as any);

		window.open(playStoreUrl);
	};

	return (
		<>
			<DesktopNavbar>
				<Container>
					<NavContainer>
						{logo || <DefaultLogo />}
						{/* TODO: add user login state for toggling UI , remove hardcoded booleans */}
						{!isLoggedIn && (
							<ButtonsContainer>
								<LoginButtonContainerDesktop>
									<Link href="/login" passHref>
										<Button>Login / SignUp</Button>
									</Link>
								</LoginButtonContainerDesktop>
								<LoginButtonContainerMobile>
									<Link href="/login" passHref>
										<Button
											padding="login"
											font-size="login"
											minWidth="max"
											size="small"
										>
											Login / SignUp
										</Button>
									</Link>
								</LoginButtonContainerMobile>
								<PlayStoreButtonContainer
									onClick={() => openPlayStoreUrl()}
									tw="hidden md:block cursor-pointer"
								>
									<PlayStoreButton />
								</PlayStoreButtonContainer>
							</ButtonsContainer>
						)}
						{isLoggedIn && (
							<LoggedInContainer>
								<IconsContainer>
									<Link href="/jobs" passHref>
										<IconLink>
											{activePage === "job" ? (
												<JobBagIcon />
											) : (
												<JobBagOutlineIcon />
											)}
										</IconLink>
									</Link>
									<Link href={userProfileLink} passHref>
										<IconLink>
											{activePage === "profile" ? (
												<UserIcon />
											) : (
												<UserOutlineIcon />
											)}
										</IconLink>
									</Link>
								</IconsContainer>
								<Link href={userProfileLink} passHref>
									<UserNameContainer>
										<FullNameContainer>
											<FullNameLabel>
												{user?.full_name}
											</FullNameLabel>
											<CityLabel>
												{user?.profile?.city_obj?.name}
											</CityLabel>
										</FullNameContainer>
										{user?.photo_icon_path ? (
											<div>
												<CustomImage
													height={48}
													width={48}
													src={`/${user?.photo_icon_path}?mode=crop&crop=faces&h=48&`}
												/>
											</div>
										) : (
											<UserInitialsLabel>
												{getUserIntials()}
											</UserInitialsLabel>
										)}
									</UserNameContainer>
								</Link>
							</LoggedInContainer>
						)}
					</NavContainer>
				</Container>
			</DesktopNavbar>
			<MobileNavbar tw="w-full block md:hidden bg-primary">
				{!isLoggedIn && (
					<NavItemsContainer>
						{logo || <ApnaLogo />}
						<MobileButtonsContainer>
							<Link href="/login" passHref>
								<Button tw="px-2 text-sm" size="small">
									Login / SignUp
								</Button>
							</Link>
						</MobileButtonsContainer>
					</NavItemsContainer>
				)}
				{isLoggedIn && (
					<MobileNavContainer>
						{logo || <ApnaLogo />}
						<NavItemsInnerContainer>
							<Link href="/jobs" passHref>
								<MobileLinkStyle active={activePage === "job"}>
									<span>
										{activePage === "job" ? (
											<JobBagIcon />
										) : (
											<JobBagOutlineIcon />
										)}
									</span>

									<JobsLabel>Jobs</JobsLabel>
								</MobileLinkStyle>
							</Link>
							<Link href={userProfileLink} passHref>
								<MobileLinkStyle active={activePage === "profile"}>
									<span>
										{activePage === "profile" ? (
											<UserIcon />
										) : (
											<UserOutlineIcon />
										)}
									</span>

									<CardLabel>Card</CardLabel>
								</MobileLinkStyle>
							</Link>
						</NavItemsInnerContainer>
					</MobileNavContainer>
				)}
			</MobileNavbar>
		</>
	);
};

export default Navbar;

const DesktopNavbar = styled.nav`
	background: ${({ theme }) => theme.colors.primary};
	display: none;
	width: 100%;
	padding: 1rem;
	${bp.md} {
		display: block;
	}
`;

const LogoStyles = styled.div`
	height: 44px;
	width: 44px;
	svg {
		height: 100%;
		width: 100%;
	}
	${bp.md} {
		height: 64px;
		width: 64px;
	}
`;

const NavContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;

	${bp.sm} {
		height: 100px;
	}
`;

const ButtonsContainer = styled.div`
	display: flex;
	> * + * {
		margin-left: 0.5rem;
	}
`;

const LoginButtonContainerDesktop = styled.div`
	display: none;
	min-width: max-content;
	${bp.md} {
		display: block;
	}
`;
const LoginButtonContainerMobile = styled.div`
	display: block;
	${bp.md} {
		display: none;
	}
`;
const PlayStoreButtonContainer = styled.div`
	display: none;
	cursor: pointer;
	${bp.md} {
		display: block;
	}
`;

const LoggedInContainer = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
	align-items: center;
`;
const IconsContainer = styled.div`
	display: flex;
	padding-left: 7rem;
	> * + * {
		margin-left: 3.5rem;
	}
`;
const IconLink = styled.a`
	text-decoration-line: none;
`;

const UserNameContainer = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	> * + * {
		margin-left: 0.75rem;
	}
`;
const FullNameContainer = styled.div`
	display: flex;
	align-items: flex-end;
	flex-direction: column;
`;
const FullNameLabel = styled.p`
	margin-bottom: 0px;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 700;
	color: #fff;
`;
const CityLabel = styled.span`
	font-size: 0.75rem;
	line-height: 1rem;
	color: #fff;
	font-weight: 500;
`;
const UserInitialsLabel = styled.div`
	height: 3rem;
	width: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 700;
	color: #fff;
	background-color: #2bb792;
	border-radius: 9999px;
`;

const MobileNavbar = styled.nav`
    width: 100%:
    display: block;
    background-color: #4d3951;
    ${bp.md} {
        display: none;
    }
`;

const MobileNavContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 1.75rem;
`;

const NavItemsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
`;
const MobileButtonsContainer = styled.div`
	display: block;
	${bp.md} {
        display:" none;
	}
`;
const NavItemsInnerContainer = styled.div`
	min-width: 100%;
	display: flex;
	justify-content: space-around;
	padding-top: 1.75rem;
`;

const JobsLabel = styled.span`
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 600;
	margin: 0.25rem;
`;

const CardLabel = styled.span`
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 600;
	margin: 0.25rem;
`;
const MobileLinkStyle = styled.a`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	text-decoration-line: none;
	color: #fff;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 4px solid transparent;
	border-bottom: ${(props) => props.active && "4px solid #fff"};
`;
