import React from "react";

const ViUserIcon = () => {
	return (
		<svg
			width={18}
			height={20}
			viewBox="0 0 18 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.98968 2.40595C7.57349 2.40595 6.42369 3.59488 6.42369 5.06149C6.42369 6.52811 7.57349 7.71704 8.98968 7.71704H9.01002C10.4272 7.71704 11.576 6.52811 11.576 5.06149C11.576 3.59488 10.4272 2.40595 9.01002 2.40595H8.98968ZM13.9008 5.06148C13.9008 7.85236 11.7068 10.123 8.98971 10.123C6.29295 10.123 4.09989 7.85236 4.09989 5.06148C4.09989 2.2706 6.29295 0 8.98971 0H9.01005C11.7068 0 13.9008 2.2706 13.9008 5.06148ZM14.0823 17.5941C14.5027 17.5941 14.8572 17.2272 14.8572 16.7921V16.4903C14.8572 14.7922 13.5224 13.4117 11.8824 13.4117H6.1189C4.47798 13.4117 3.14316 14.7922 3.14316 16.4903V16.7921C3.14316 17.2272 3.49769 17.5941 3.91809 17.5941H14.0823ZM6.1187 11.0058H11.8823C14.8037 11.0058 17.1818 13.4659 17.1818 16.4903V16.7921C17.1818 18.5614 15.7918 20 14.0821 20H3.9179C2.20821 20 0.818176 18.5614 0.818176 16.7921V16.4903C0.818176 13.4659 3.19625 11.0058 6.1187 11.0058Z"
				fill="#F5F7FB"
			/>
		</svg>
	);
};

export default ViUserIcon;
