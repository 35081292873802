import styled, { css } from "styled-components";

const BorderStyles = css`
	${(props) => {
		if (props.success) {
			return `border: 1px solid #22C38E;
                    background: #F5F7FB;`;
		}
		if (props.error) {
			return "border: 1px solid #F85963";
		}
		return "border: 1px solid #DAE0E5";
	}}
`;

const Input = styled.input`
	height: 48px;
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 4px;
	color: #31445a;
	padding-left: 12px;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #31445a;
	width: 100%;

	&:focus {
		border: 1px solid #31445a;
		box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.1);
		outline: none;
	}

	&::placeholder {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #9ba9b8;
	}
	${BorderStyles}
`;

export default Input;
