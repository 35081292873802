/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import ChevronDownIcon from "assets/svgs/ChevronDownIcon";
import styled from "styled-components";
import Input from "./TextInput";
import CheckmarkIcon from "../../../assets/svgs/CheckmarkIcon";

const AutoCompleteMaterial = ({
	options,
	labelKey,
	autoCompleteValue,
	defaultValue,
	onChange,
	disabled = false,
	customCta = null,
	renderInput,
	id,
	showOptionOnTypeStart,
	freeSolo = false,
}) => {
	const callBacks = {};

	if (showOptionOnTypeStart) {
		callBacks.filterOptions = (opt, state) => {
			if (state.inputValue === "") {
				return [];
			}
			return opt.filter((o) =>
				o[labelKey].toLowerCase().includes(state.inputValue.toLowerCase())
			);
		};
	}

	const {
		getRootProps,
		// getInputLabelProps,
		getInputProps,
		getListboxProps,
		getOptionProps,
		groupedOptions,
		value,
		getPopupIndicatorProps,
	} = useAutocomplete({
		id,
		value: autoCompleteValue,
		defaultValue,
		options,
		onChange,
		freeSolo,
		getOptionLabel: (option) => (labelKey ? option[labelKey] ?? "" : option?.name),
		getOptionSelected: (option) => option[labelKey],

		// TODO We can integrate custom filtering for area search to improve performance
		// TODO rendering 2k+ dom nodes slows down page on low/mid range devices
		...callBacks,
		// filterOptions: (options, state) => {
		// 	if (state.inputValue === "") {
		// 		return [];
		// 	}
		// 	return options.filter((o) =>
		// 		o[labelKey].toLowerCase().includes(state.inputValue.toLowerCase())
		// 	);
		// },
	});

	return (
		<Container>
			<Container {...getRootProps()}>
				{!renderInput && (
					<Input
						onBlur={() => {}}
						id="select"
						disabled={disabled}
						{...getInputProps()}
					/>
				)}
				{renderInput &&
					renderInput({ onBlur: () => {}, disabled, ...getInputProps() })}
				<DownIconSpan {...getPopupIndicatorProps()}>
					<ChevronDownIcon />
				</DownIconSpan>
			</Container>
			{groupedOptions.length > 0 ? (
				<ListBox {...getListboxProps()}>
					{groupedOptions.map((option, index) => (
						<ListBoxItem {...getOptionProps({ option, index })}>
							<ListBoxItemLabel>{option[labelKey]}</ListBoxItemLabel>
							<span>
								<IconContainer
									hasIcon={
										value
											? option[labelKey] === value[labelKey]
											: false
									}
								>
									<CheckmarkIcon />
								</IconContainer>
							</span>
						</ListBoxItem>
					))}
					{customCta && <CustomCtaLabel>{customCta}</CustomCtaLabel>}
				</ListBox>
			) : null}
		</Container>
	);
};

export default AutoCompleteMaterial;

const Container = styled.div`
width: 100%:
position: relative;

`;
const DownIconSpan = styled.span`
	postion: absolute;
	right: 0.5rem;
	top: 0.5rem;
	cursor: pointer;
`;

const CustomCtaLabel = styled.div`
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-bottom: 1rem;
`;
const IconContainer = styled.div`
	height: 24px;
	width: 24px;
	border-radius: 9999px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #eaedf2;
	background: ${(props) => props.hasIcon && "#2bb792"};

	svg {
		display: ${(props) => (props.hasIcon ? "block" : "none")};
		height: 10px;
	}
`;

const ListBox = styled.ul`
	width: 100%;
	max-height: 280px;
	border-radius: 0.25rem;
	box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.1), 0 1px 2px -1px rgb(0, 0, 0, 0.1);
	margin-top: 0.25rem;
	margin-left: 0px;
	padding: 0px;
	z-index: 50;
	position: absolute;
	background-color: #fff;
	list-style-type: none;
	overflow: auto;
	border-width: 1px;
	li[data-focus="true"] {
		background-color: #d6f5ed;
		cursor: pointer;
		& ${IconContainer} {
			border: 1px solid #2bb792;
		}
	}

	li {
		&::active {
			background-color: #d6f5ed;
			cursor: pointer;
		}
	}
`;
const ListBoxItem = styled.li`
	display: flex;
	justify-content: space-between;
	padding: 0.5rem;
`;

const ListBoxItemLabel = styled.span`
	font-size: 0.875rem; /* 14px */
	line-height: 1.25rem;
	font-weight: 400;
	color: rgb(31 41 55);
`;
