import styled, { css } from "styled-components";

const GrayButtonStyles = css`
	background: #9ba9b8;
	border: 1px solid #9ba9b8;
	color: #fff;
`;

const GreenButtonStyle = css`
	background: #2bb792;
	border: 1px solid #2bb792;
	color: #fff;

	&:hover {
		box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
			0px 8px 8px -4px rgba(24, 39, 75, 0.08);
	}
`;

const SmallButtonStyle = css`
	${(props) =>
		props.size === "small" &&
		`
            height: 32px;
            line-height: 32px;`}
`;

const BlockButtonStyle = css`
	${({ block }) => block && "width: 100%;"}
`;

const DisabledButtonStyle = css`
	${({ disabled }) => disabled && `opacity: 0.7; cursor: default`}
`;

const Variants = css`
	${(props) => props.variant === "gray" && GrayButtonStyles}

	${(props) => props.variant === "green" && GreenButtonStyle}

	${(props) =>
		props.outline &&
		props.variant === "gray" &&
		`
        background: transparent;
        color: #9BA9B8;
        border: 1px solid #9BA9B8;

        &:hover {
         ${GrayButtonStyles}
        }
    `}

	${(props) =>
		props.outline &&
		props.variant === "green" &&
		`
        background: transparent;
        color: #2bb792;
        border: 1px solud #2bb792;


        &:hover {
            ${GreenButtonStyle}
			
        }
    `}
	${(props) =>
		props.padding === "login" &&
		`
		padding: 0 0.5rem;
	`}
	${(props) =>
		props.fontSize === "login" &&
		`
		font-size: 0.875rem;
		line-height: 1.25rem;
	`}
	${(props) =>
		props.minWidth === "max" &&
		`
		min-width: max-content;
	`}
`;

const ButtonBase = styled.button`
	height: 48px;
	border-radius: 4px;
	line-height: 48px;
	font-weight: bold;
	font-size: 16px;
	text-align: center;
	padding: 0 20px;
	outline: none;
	color: white;
	cursor: pointer;
	transition-duration: 200ms;
	transition-property: all;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	${Variants}
	${SmallButtonStyle}
    ${BlockButtonStyle}
    ${DisabledButtonStyle}
`;

const Button = ({ children, icon, ...props }) => {
	const { variant = "green" } = props;
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<ButtonBase variant={variant} {...props}>
			{children}
		</ButtonBase>
	);
};

export default Button;
